// Primary imports.
import 'core-js/stable';
// import App from "./App.vue";
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'

// // Import Bootstrap an BootstrapVue CSS files (order is important)
// // import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/scss/bootstrap.scss';
// import 'bootstrap-vue/src/index.scss';


// Styles
import './sass/main.scss';

Vue.use(BootstrapVue);


/**
 * Components to be loaded globally (always in the bundle)
 */
// Vue.component(
//   'App',
//   require('./App.vue').default,
// );

/**
 * Dynamic Imports
 * For components that should only be loaded on pages which use them,
 * import them dynamically here. The 'webpackChunkName' specifies how the
 * saved chunk file will be named (ie, showcase-lazy.js). Chunked files
 * are saved in the 'dist' folder in the theme root.
 */

// Vue.component(
//   'pba-icon',
//   () => import(
//     /* webpackChunkName: "icon-lazy" */
//     './components/icon/icon.vue'),
// );

/**
 * These dynamic imports are also lazy loaded, but are loaded via
 * rel="prefetch" to defer their loading until after other scripts
 */

// Vue.component(
//   'pba-icon',
//   () => import(
//     /* webpackChunkName: "icon-lazy" */
//     /* webpackPrefetch: true */
//     './components/icon/icon.vue'),
// );

/* eslint-disable no-new */
Vue.config.productionTip = false

// new Vue({
//   render: h => h(App)
// }).$mount('#app')

new Vue({
  el: '#app',
  comments: true,
  delimiters: ['${', '}'],
  components: {},
});
